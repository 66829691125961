import Header from '../components/Header';
import Footer from '../components/Footer';
import styles from '../styles/pages/Policy.module.scss';
import classnames from 'classnames';
import axios from 'axios';
import { useState, useEffect } from 'react';
import PolicyTitle from '../components/PolicyTitle';
import PolicyRichText from '../components/PolicyRichText';
import PolicyTable from '../components/PolicyTable';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';

export default function Highlight() {
  const [pageData, setPageData] = useState(null);
  const BASE_PATH = process.env.REACT_APP_BACKEND_URL;
  const params = useParams();

  useEffect(() => {
    if (params && params.id) {
      if (params.id.includes('.html')) {
        params.id = params.id.split('.html')[0];
      }
      axios
        .get(BASE_PATH + '/api/policy-pages/' + params.id + '?populate=deep')
        .then((response) => {
          setPageData(response.data?.data?.attributes);
        });
    }
  }, [params, BASE_PATH]);

  return (
    <div className={styles.container}>
      <Helmet>
        <title>{pageData?.Page_title}</title>
        <meta name="title" content={pageData?.Meta_title} />
        <meta name="description" content={pageData?.Meta_description} />
        <link rel="icon" href="/favicon.png" />
      </Helmet>

      <Header />

      <main className={classnames(styles.main, styles.policy)}>
        <div className={styles.container}>
          {pageData?.Content.map((item, index) => {
            switch (item.__component) {
              case 'policy-components.policy-title':
                return <PolicyTitle data={item} key={index} />;
              case 'policy-components.policy-rich-text':
                return <PolicyRichText data={item} key={index} />;
              case 'policy-components.policy-table':
                return <PolicyTable data={item} key={index} />;
              default:
                return null;
            }
          })}
        </div>
      </main>

      <Footer />
    </div>
  );
}
