import styles from '../styles/components/Quote.module.scss';

export default function Quote({ data }) {
  return (
    <section className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.quote}>{data?.Quote}</div>
        <div className={styles.author}>{data?.Author}</div>
        <div className={styles.authorTitle}>{data?.AuthorTitle}</div>
      </div>
    </section>
  );
}
