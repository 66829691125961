import styles from '../styles/components/Popup.module.scss';
import Vimeo from '@u-wave/react-vimeo';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { useContext } from 'react';
import { MobileContext } from '../context/mobileContext';
import classnames from 'classnames';

export default function Popup({ closePopup, popupType, popupData }) {
  const BASE_PATH = process.env.REACT_APP_BACKEND_URL;
  const isMobile = useContext(MobileContext);

  const carouselSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    swipe: !isMobile ? false : true,
    swipeToSlide: !isMobile ? false : true,
    touchThreshold: 100,
    focusOnSelect: true,
    centerMode: true,
    centerPadding: 0,
    fade: true,
    dots: true,
    appendDots: (dots) => (
      <div>
        <ul className={styles.customCarouselDots}> {dots} </ul>
      </div>
    ),
    customPaging: (i) => <div className={styles.dots}></div>,
  };

  const imageCollage = (
    <div
      className={classnames(styles.imageCollageWrapper, styles.displayScroll)}
    >
      {popupData?.Popup?.Images?.data && (
        <>
          {popupData.Popup.Images.data.map((item, index) => {
            return (
              <div className={styles.gridItem} key={index}>
                <img
                  src={BASE_PATH + item.attributes.url}
                  alt={BASE_PATH + item.attributes.alternativeText}
                />
              </div>
            );
          })}
        </>
      )}
      {!popupData?.Popup?.Images?.data && (
        <>
          {popupData.Popup?.Images?.data ? (
            <img
              src={BASE_PATH + popupData.Popup.Images.data[0].attributes.url}
              alt={
                BASE_PATH +
                popupData.Popup.Images.data[0].attributes.alternativeText
              }
            />
          ) : (
            <img
              src={BASE_PATH + popupData.Image.data.attributes.url}
              alt={BASE_PATH + popupData.Image_alt_text}
            />
          )}
        </>
      )}
    </div>
  );

  const careousel = (
    <div className={styles.carouselWrapper}>
      {popupData?.Popup?.Images?.data && (
        <Slider {...carouselSettings}>
          {popupData.Popup.Images?.data.map((item, index) => {
            return (
              <div key={index}>
                <img
                  src={BASE_PATH + item.attributes.url}
                  alt={item.attributes.alternativeText}
                />
              </div>
            );
          })}
        </Slider>
      )}
      {!popupData?.Popup?.Images?.data && (
        <>
          {popupData.Popup?.Images?.data ? (
            <img
              src={BASE_PATH + popupData.Popup.Images.data[0].attributes.url}
              alt={
                BASE_PATH +
                popupData.Popup.Images.data[0].attributes.alternativeText
              }
            />
          ) : (
            <img
              src={BASE_PATH + popupData.Image.data.attributes.url}
              alt={BASE_PATH + popupData.Image_alt_text}
            />
          )}
        </>
      )}
    </div>
  );

  const video = (
    <div className={styles.popupWrapper}>
      {!popupData?.Popup?.Embed && popupData?.Popup?.Video?.data && (
        <video
          autoPlay
          muted
          loop
          src={BASE_PATH + popupData.Popup.Video.data?.attributes.url}
        ></video>
      )}
      {popupData?.Popup?.Embed && popupData?.Popup?.VideoEmbed && (
        <div className={styles.vimeoScale}>
          <Vimeo
            video={popupData.Popup.VideoEmbed}
            autoplay
            loop={true}
            muted={true}
          />
        </div>
      )}
      {popupData?.Popup?.Embed && !popupData?.Popup?.VideoEmbed && (
        <>
          {popupData.Popup?.Images?.data ? (
            <img
              src={BASE_PATH + popupData.Popup.Images.data[0].attributes.url}
              alt={
                BASE_PATH +
                popupData.Popup.Images.data[0].attributes.alternativeText
              }
            />
          ) : (
            <img
              src={BASE_PATH + popupData.Image.data.attributes.url}
              alt={BASE_PATH + popupData.Image_alt_text}
            />
          )}
        </>
      )}
      {!popupData?.Popup?.Embed && !popupData?.Popup?.Video?.data && (
        <>
          {popupData.Popup?.Images?.data ? (
            <img
              src={BASE_PATH + popupData.Popup.Images.data[0].attributes.url}
              alt={
                BASE_PATH +
                popupData.Popup.Images.data[0].attributes.alternativeText
              }
            />
          ) : (
            <img
              src={BASE_PATH + popupData.Image.data.attributes.url}
              alt={BASE_PATH + popupData.Image_alt_text}
            />
          )}
        </>
      )}
    </div>
  );

  const staticEnlarged = (
    <div className={styles.popupWrapper}>
      {popupData?.Popup?.Images?.data ? (
        <img
          src={BASE_PATH + popupData.Popup.Images.data[0].attributes.url}
          alt={
            BASE_PATH +
            popupData.Popup.Images.data[0].attributes.alternativeText
          }
        />
      ) : (
        <img
          src={BASE_PATH + popupData.Image.data.attributes.url}
          alt={BASE_PATH + popupData.Image_alt_text}
        />
      )}
    </div>
  );

  return (
    <div
      className={styles.container}
      onClick={() => {
        if (popupType !== 'ImageCarousel') {
          closePopup();
        } else {
          if (
            !popupData.Popup.Images?.data ||
            popupData.Popup.Images?.data.length === 1
          ) {
            closePopup();
          }
        }
      }}
    >
      <div className={styles.title}>{popupData.Title}</div>
      {popupType === 'StaticEnlarged' && staticEnlarged}
      {popupType === 'Video' && video}
      {popupType === 'ImageCarousel' && careousel}
      {popupType === 'ImageCollage' && imageCollage}
      <div className={styles.close} onClick={closePopup}>
        <div>X</div>
        <div>Back to the wall</div>
      </div>
    </div>
  );
}
