import Header from '../components/Header';
import Footer from '../components/Footer';
import styles from '../styles/pages/Wall.module.scss';
import classnames from 'classnames';
import Masonry from 'react-masonry-css';
import axios from 'axios';
import { useState, useEffect } from 'react';
import Popup from '../components/Popup';
import { Helmet } from 'react-helmet';

export default function Wall() {
  const [items, setItems] = useState(null);
  const [filteredCards, setFilteredCards] = useState(null);
  const [pageData, setPageData] = useState(null);
  const [categories, setCategories] = useState(null);
  const [activeFilters, setActiveFilters] = useState(null);
  const [isPopupOpened, setIsPopupOpened] = useState(false);
  const [popupType, setPopupType] = useState(null);
  const [popupData, setPopupData] = useState(null);

  const BASE_PATH = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    axios.get(BASE_PATH + '/api/wall-page?populate=deep').then((response) => {
      setPageData(response.data?.data?.attributes);
      setFilteredCards(response.data?.data?.attributes?.Cards);
    });
    axios
      .get(BASE_PATH + '/api/card-categories?populate=deep')
      .then((response) => {
        setCategories(response.data?.data);
        setActiveFilters(['all']);
      });
  }, [BASE_PATH]);

  const breakpointColumnsObj = {
    default: 5,
    1100: 4,
    700: 3,
    500: 2,
  };

  const toggleFilters = (filter) => {
    let tempFilters = activeFilters;

    if (filter !== 'all') {
      tempFilters = tempFilters.filter((item) => {
        if (item !== 'all') {
          return item;
        } else {
          return null;
        }
      });

      if (!tempFilters.includes(filter)) {
        tempFilters.push(filter);
      } else {
        tempFilters = tempFilters.filter((item) => {
          if (item !== filter) {
            return item;
          } else {
            return null;
          }
        });
        if (tempFilters.length === 0) {
          tempFilters = ['all'];
        }
      }
    } else {
      tempFilters = ['all'];
    }
    setActiveFilters(tempFilters);
    filterItems(tempFilters);
  };

  const filterItems = (filter) => {
    const tempItems = [];
    pageData?.Cards.map((item) => {
      if (
        item.category &&
        (filter.includes('all') ||
          filter.includes(item.category.data.attributes.UID))
      ) {
        tempItems.push(item);
      }
      return null;
    });
    setFilteredCards(tempItems);
  };

  const openPopup = (popupType, popupData) => {
    setIsPopupOpened(true);
    setPopupType(popupType);
    setPopupData(popupData);
  };

  const closePopup = () => {
    setIsPopupOpened(false);
  };

  useEffect(() => {
    setItems(
      filteredCards?.map((item, index) => {
        return (
          <div
            key={index}
            onClick={() => {
              if (item.PopupType !== null) {
                if (item.PopupType !== 'None') {
                  openPopup(item.PopupType, item);
                }
              }
            }}
          >
            <div>
              <img
                src={BASE_PATH + item.Image.data.attributes.url}
                alt={item.Image_alt_text}
                width={item.Image.data.attributes.width}
                height={item.Image.data.attributes.height}
                style={{
                  maxWidth: '100%',
                  height: 'auto',
                }}
              />
            </div>
            <div className={styles.hoverCopy}>
              <strong>{item.Title}</strong>
              <p>{item.Description}</p>
            </div>
          </div>
        );
      })
    );
  }, [filteredCards, BASE_PATH]);

  return (
    <div className={styles.container}>
      <Helmet>
        <title>{pageData?.Page_title}</title>
        <meta name="title" content={pageData?.Meta_title} />
        <meta name="description" content={pageData?.Meta_description} />
        <link rel="icon" href="/favicon.png" />
      </Helmet>
      {isPopupOpened && (
        <Popup
          closePopup={closePopup}
          popupType={popupType}
          popupData={popupData}
        />
      )}
      <Header />
      <main className={styles.main}>
        <h1>THE WALL</h1>
        <div className={styles.filterWrapper}>
          <div
            className={classnames(
              styles.filter,
              activeFilters?.includes('all') && styles.active
            )}
            onClick={() => {
              toggleFilters('all');
            }}
          >
            All
          </div>
          {categories?.map((item, index) => {
            return (
              <div
                className={classnames(
                  styles.filter,
                  activeFilters?.includes(item.attributes.UID) && styles.active
                )}
                key={index}
                onClick={() => {
                  toggleFilters(item.attributes.UID);
                }}
              >
                {item.attributes.Name}
              </div>
            );
          })}
        </div>
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className={styles.grid}
          columnClassName={styles.gridColumn}
        >
          {items}
        </Masonry>
      </main>
      <Footer />
    </div>
  );
}
