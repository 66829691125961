import Header from '../components/Header';
import Footer from '../components/Footer';
import TitleBlock from '../components/TitleBlock';
import TextContent from '../components/TextContent';
import VisualContentSingleColumn from '../components/VisualContentSingleColumn';
import VisualContentTwoColumns from '../components/VisualContentTwoColumns';
import VisualContentThreeColumns from '../components/VisualContentThreeColumns';
import styles from '../styles/pages/Highlight.module.scss';
import classnames from 'classnames';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';

import axios from 'axios';
import { useState, useEffect } from 'react';

export default function Highlight() {
  const [pageData, setPageData] = useState(null);
  const [links, setLinks] = useState([]);
  const BASE_PATH = process.env.REACT_APP_BACKEND_URL;
  const params = useParams();

  useEffect(() => {
    if (params && params.id) {
      if (params.id.includes('.html')) {
        params.id = params.id.split('.html')[0];
      }
      axios
        .get(BASE_PATH + '/api/highlight-pages/' + params.id + '?populate=deep')
        .then((response) => {
          setPageData(response.data?.data?.attributes);
        });
    }
  }, [params, BASE_PATH]);

  useEffect(() => {
    if (pageData) {
      const linkArr = [];
      pageData.Components.map((item) => {
        if (item.__component === 'home-components.links') {
          linkArr.push(item);
        }
        return null;
      });
      setLinks(linkArr);
    }
  }, [pageData]);

  return (
    <div className={styles.container}>
      <Helmet>
        <title>{pageData?.Page_title}</title>
        <meta name="title" content={pageData?.Meta_title} />
        <meta name="description" content={pageData?.Meta_description} />
        <link rel="icon" href="/favicon.png" />
      </Helmet>

      <Header />

      <main className={classnames(styles.main, styles.highlight)}>
        <div className={styles.container}>
          <TitleBlock data={pageData?.TitleBlock} />
          {pageData?.Components.map((item, index) => {
            switch (item.__component) {
              case 'generic-components.text-content':
                return <TextContent data={item} key={index} />;
              case 'generic-components.visual-content-single-column':
                return <VisualContentSingleColumn data={item} key={index} />;
              case 'generic-components.visual-content-two-columns':
                return <VisualContentTwoColumns data={item} key={index} />;
              case 'generic-components.visual-content-three-columns':
                return <VisualContentThreeColumns data={item} key={index} />;
              default:
                return null;
            }
          })}
          <div className={styles.imageSection}>
            {links.length > 0 &&
              links.map((item, index) => {
                return (
                  <div className={styles.image} key={index}>
                    <a href={item.Link_URL}>
                      <span>
                        <img
                          src={BASE_PATH + item.Image.data.attributes.url}
                          alt={item.Image_alt_text}
                          width={item.Image.data.attributes.width}
                          height={item.Image.data.attributes.height}
                          style={{
                            maxWidth: '100%',
                            height: 'auto',
                          }}
                        />
                      </span>
                    </a>
                  </div>
                );
              })}
          </div>
        </div>
      </main>

      <Footer />
    </div>
  );
}
