import styles from '../styles/components/LogoAndText.module.scss';
export default function LogoAndText({ data }) {
  const BASE_PATH = process.env.REACT_APP_BACKEND_URL;

  return (
    <section className={styles.container}>
      <div className={styles.wrapper}>
        {data?.Logo && (
          <img
            src={BASE_PATH + data.Logo?.data.attributes.url}
            alt={data.Logo?.data.attributes.alternativeText}
            width={data.Logo?.data.attributes.width}
            height={data.Logo?.data.attributes.height}
            style={{
              maxWidth: '100%',
              height: 'auto',
            }}
          />
        )}
        <div className={styles.text}>{data?.Text}</div>
      </div>
    </section>
  );
}
