import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/home";
import About from "./pages/about";
import Contact from "./pages/contact";
import Wall from "./pages/wall";
import Highlight from "./pages/highlight";
import Legal from "./pages/legal";
import './styles/globals.scss';

import { useState, useEffect } from 'react';
import { MobileContext } from './context/mobileContext';

export default function App() {

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    if (typeof window !== 'undefined') {
      setIsMobile(window.innerWidth <= 768);
      window.addEventListener('resize', handleResize);
    }
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <MobileContext.Provider value={isMobile}>
      <BrowserRouter>
        <Routes>
          <Route index element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="contact" element={<Contact />} />
          <Route path="wall" element={<Wall />} />
          <Route path="/:id" element={<Highlight />} />
          <Route path="/legal/:id" element={<Legal />} />
          <Route path="*" element={<Home />} />
        </Routes>
      </BrowserRouter>
    </MobileContext.Provider>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <App />
);
