import Header from '../components/Header';
import Footer from '../components/Footer';
import styles from '../styles/pages/Contact.module.scss';
import AnimatedImage from '../components/AnimatedImage';
import LogoAndText from '../components/LogoAndText';
import GetInTouch from '../components/GetInTouch';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

export default function Contact() {
  const [pageData, setPageData] = useState(null);
  const BASE_PATH = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    axios
      .get(BASE_PATH + '/api/contact-page?populate=deep')
      .then((response) => {
        setPageData(response.data?.data?.attributes);
      });
  }, [BASE_PATH]);

  return (
    <div className={styles.container}>
      <Helmet>
        <title>{pageData?.Page_title}</title>
        <meta name="title" content={pageData?.Meta_title} />
        <meta name="description" content={pageData?.Meta_description} />
        <link rel="icon" href="/favicon.png" />
      </Helmet>

      <Header />

      <main className={styles.main}>
        <GetInTouch data={pageData?.Get_In_Touch} />
        <AnimatedImage data={pageData?.Map} layout={'full'} />
        <LogoAndText data={pageData?.Logo_And_Text} />
      </main>

      <Footer />
    </div>
  );
}
