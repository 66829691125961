import styles from '../styles/components/VisualContentSingleColumn.module.scss';
import Vimeo from '@u-wave/react-vimeo';

export default function VisualContentSingleColumn({ data }) {
  const BASE_PATH = process.env.REACT_APP_BACKEND_URL;
  return (
    <section className={styles.container}>
      {data?.Embed && (
        <Vimeo
          video={data.Embed}
          autoplay={data.VideoAutoplay}
          background={data.VideoAutoplay}
          loop={true}
          muted={true}
          responsive={true}
        />
      )}

      {!data?.Embed && data?.Video?.data && (
        <video
          autoPlay={data?.VideoAutoplay}
          muted
          loop
          src={BASE_PATH + data.Video.data.attributes.url}
        />
      )}

      {!data?.Embed && !data?.Video?.data && (
        <img
          src={BASE_PATH + data.Image.data.attributes.url}
          alt={data.Image.data.attributes.alternativeText}
          width={data.Image.data.attributes.width}
          height={data.Image.data.attributes.height}
          style={{
            maxWidth: '100%',
            height: 'auto',
          }}
        />
      )}
    </section>
  );
}

// Embed > Video > Image
