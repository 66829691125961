import styles from '../styles/components/AnimatedImage.module.scss';
import classnames from 'classnames';

export default function AnimatedImage({ data, layout }) {
  const BASE_PATH = process.env.REACT_APP_BACKEND_URL;
  const imageUrl = data?.Image?.data?.attributes?.url;
  const imageWidth = data?.Image?.data?.attributes?.width;
  const imageHeight = data?.Image?.data?.attributes?.height;
  const imageAlt = data?.Image?.data?.attributes?.alternativeText;
  return (
    <section
      className={classnames(
        styles.container,
        layout === 'full' ? styles.full : ''
      )}
    >
      <div className={styles.imageWrapper}>
        {imageUrl && (
          <img
            src={BASE_PATH + imageUrl}
            alt={imageAlt}
            width={imageWidth}
            height={imageHeight}
            style={{
              maxWidth: '100%',
              height: 'auto',
            }}
          />
        )}
      </div>
    </section>
  );
}
