import styles from '../styles/components/HeaderLogo.module.scss';

export default function HeaderLogo({ componentData }) {
  const BASE_PATH = process.env.REACT_APP_BACKEND_URL;
  return (
    <>
      <span className={styles.logo}>
        {componentData && (
          <a href="/">
            <span>
              <img
                src={BASE_PATH + componentData.Site_logo.data.attributes.url}
                alt={componentData.Logo_alt_text}
                width={componentData.Site_logo.data.attributes.width}
                height={componentData.Site_logo.data.attributes.height}
                style={{
                  maxWidth: '100%',
                  height: 'auto',
                }}
              />
            </span>
          </a>
        )}
      </span>
    </>
  );
}
