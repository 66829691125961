import styles from '../styles/components/TwoColumnText.module.scss';
import { useContext, useState } from 'react';
import { MobileContext } from '../context/mobileContext';
import classnames from 'classnames';

export default function TwoColumnText({ data }) {
  const isMobile = useContext(MobileContext);
  const [isLeftContentOpen, setIsLeftContentOpen] = useState(false);
  const [isRightContentOpen, setIsRightContentOpen] = useState(false);

  const LeftContent = (
    <>
      <div
        className={styles.contentTitle}
        onClick={() => setIsLeftContentOpen((previousState) => !previousState)}
      >
        {data.LeftTitle}
        {isMobile && (
          <span
            className={classnames(
              styles.arrow,
              isLeftContentOpen ? styles.down : styles.right
            )}
          ></span>
        )}
      </div>
      <ul className={!isLeftContentOpen ? styles.closed : ''}>
        {data.LeftItems.map((element) => {
          return <li key={element.id}>{element.ItemTitle}</li>;
        })}
      </ul>
    </>
  );

  const RightContent = (
    <>
      <div
        className={styles.contentTitle}
        onClick={() => setIsRightContentOpen((previousState) => !previousState)}
      >
        {data.RightTitle}
        {isMobile && (
          <span
            className={classnames(
              styles.arrow,
              isRightContentOpen ? styles.down : styles.right
            )}
          ></span>
        )}
      </div>
      <ul className={!isRightContentOpen ? styles.closed : ''}>
        {data.RightItems.map((element) => {
          return <li key={element.id}>{element.ItemTitle}</li>;
        })}
      </ul>
    </>
  );

  return (
    <section className={styles.container}>
      <div className={styles.containerLeft}>
        <span className={styles.sectionTitle}>{data.Title}</span>
        {LeftContent}
        {isMobile && RightContent}
      </div>
      {!isMobile && <div className={styles.containerRight}>{RightContent}</div>}
    </section>
  );
}
