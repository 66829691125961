import styles from '../styles/components/Footer.module.scss';
import axios from 'axios';
import { useEffect, useState, useContext } from 'react';
import { MobileContext } from '../context/mobileContext';

export default function Footer() {
  const [componentData, setComponentData] = useState(null);
  const BASE_PATH = process.env.REACT_APP_BACKEND_URL;
  const isMobile = useContext(MobileContext);

  useEffect(() => {
    axios.get(BASE_PATH + '/api/footer?populate=deep').then((response) => {
      setComponentData(response.data?.data?.attributes);
    });
  }, [BASE_PATH]);

  const logo = (
    <span className={styles.logoWrapper}>
      <span className={styles.logo}>
        {componentData && (
          <img
            src={BASE_PATH + componentData.Logo.data.attributes.url}
            alt={componentData.Logo_alt_text}
            width={componentData.Logo.data.attributes.width}
            height={componentData.Logo.data.attributes.height}
            style={{
              maxWidth: '100%',
              height: 'auto',
            }}
          />
        )}
      </span>
    </span>
  );

  return (
    <footer className={styles.footer}>
      <ul className={styles.links}>
        <li>{componentData?.Copyright_line}</li>
        {componentData &&
          componentData.Legal_menu.map((item, index) => {
            return (
              <li key={index}>
                <a href={item.Link_URL}>{item.Link_text}</a>
              </li>
            );
          })}
      </ul>
      {!isMobile && logo}
      <ul className={styles.social}>
        {isMobile && logo}
        {componentData &&
          componentData.Social_menu.map((item, index) => {
            return (
              <li key={index}>
                <a
                  href={item.Link_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={BASE_PATH + item.Image.data.attributes.url}
                    alt={item.Image_alt_text}
                    width={item.Image.data.attributes.width}
                    height={item.Image.data.attributes.height}
                    className={styles.invertImageColors}
                    style={{
                      maxWidth: '100%',
                      height: 'auto',
                    }}
                  />
                </a>
              </li>
            );
          })}
      </ul>
    </footer>
  );
}
