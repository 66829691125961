import styles from '../styles/components/PolicyRichText.module.scss';
import ReactMarkdown from 'react-markdown';

export default function PolicyRichText({ data }) {
  return (
    <div className={styles.policyRichText}>
      <ReactMarkdown>{data?.RichText}</ReactMarkdown>
    </div>
  );
}
