import Header from '../components/Header';
import Footer from '../components/Footer';
import HeroVideo from '../components/HeroVideo';
import styles from '../styles/pages/Home.module.scss';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

export default function Home() {
  const [pageData, setPageData] = useState(null);
  const BASE_PATH = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    axios.get(BASE_PATH + '/api/home-page?populate=deep').then((response) => {
      setPageData(response.data?.data?.attributes);
    });
  }, [BASE_PATH]);

  return (
    <div className={styles.container}>
      <Helmet>
        <title>{pageData?.Page_title}</title>
        <meta name="title" content={pageData?.Meta_title} />
        <meta name="description" content={pageData?.Meta_description} />
        <link rel="icon" href="/favicon.png" />
      </Helmet>

      <Header />

      <main className={styles.main}>
        {pageData?.Home_video && <HeroVideo data={pageData.Home_video} />}
        <div className={styles.imageSection}>
          {pageData &&
            pageData.Links.map((item, index) => {
              return (
                <div className={styles.image} key={index}>
                  <a href={item.Link_URL}>
                    <span>
                      <img
                        src={BASE_PATH + item.Image.data.attributes.url}
                        alt={item.Image_alt_text}
                        width={item.Image.data.attributes.width}
                        height={item.Image.data.attributes.height}
                        style={{
                          maxWidth: '100%',
                          height: 'auto',
                        }}
                      />
                    </span>
                  </a>
                </div>
              );
            })}
        </div>
      </main>

      <Footer />
    </div>
  );
}
