import styles from '../styles/components/VisualContentThreeColumns.module.scss';
import Vimeo from '@u-wave/react-vimeo';
import { MobileContext } from '../context/mobileContext';
import classnames from 'classnames';
import { useContext } from 'react';

export default function VisualContentThreeColumns({ data }) {
  const BASE_PATH = process.env.REACT_APP_BACKEND_URL;
  const isMobile = useContext(MobileContext);

  const firstColumn = (
    <div>
      {data?.FirstEmbed && (
        <Vimeo
          video={data.FirstEmbed}
          autoplay={data.FirstVideoAutoplay}
          background={data.FirstVideoAutoplay}
          loop={true}
          muted={true}
          responsive={true}
        />
      )}
      {!data?.FirstEmbed && data?.FirstVideo?.data && (
        <video
          autoPlay={data?.FirstVideoAutoplay}
          muted
          loop
          src={BASE_PATH + data.FirstVideo.data.attributes.url}
        />
      )}
      {!data?.FirstEmbed &&
        !data?.FirstVideo?.data &&
        data?.FirstImage?.data && (
          <img
            src={BASE_PATH + data.FirstImage.data.attributes.url}
            alt={data.FirstImage.data.attributes.alternativeText}
            width={data.FirstImage.data.attributes.width}
            height={data.FirstImage.data.attributes.height}
            style={{
              maxWidth: '100%',
              height: 'auto',
            }}
          />
        )}
    </div>
  );
  const secondColumn = (
    <div>
      {data?.SecondEmbed && (
        <Vimeo
          video={data.SecondEmbed}
          autoplay={data.SecondVideoAutoplay}
          background={data.SecondVideoAutoplay}
          loop={true}
          muted={true}
          responsive={true}
        />
      )}
      {!data?.SecondEmbed && data?.SecondVideo?.data && (
        <video
          autoPlay={data?.SecondVideoAutoplay}
          muted
          loop
          src={BASE_PATH + data.SecondVideo.data.attributes.url}
        />
      )}
      {!data?.SecondEmbed &&
        !data?.SecondVideo?.data &&
        data?.SecondImage?.data && (
          <img
            src={BASE_PATH + data.SecondImage.data.attributes.url}
            alt={data.SecondImage.data.attributes.alternativeText}
            width={data.SecondImage.data.attributes.width}
            height={data.SecondImage.data.attributes.height}
            style={{
              maxWidth: '100%',
              height: 'auto',
            }}
          />
        )}
    </div>
  );
  const thirdColumn = (
    <div>
      {data?.ThirdEmbed && (
        <Vimeo
          video={data.ThirdEmbed}
          autoplay={data.ThirdVideoAutoplay}
          background={data.ThirdVideoAutoplay}
          loop={true}
          muted={true}
          responsive={true}
        />
      )}
      {!data?.ThirdEmbed && data?.ThirdVideo?.data && (
        <video
          autoPlay={data?.ThirdVideoAutoplay}
          muted
          loop
          src={BASE_PATH + data.ThirdVideo.data.attributes.url}
        />
      )}
      {!data?.ThirdEmbed &&
        !data?.ThirdVideo?.data &&
        data?.ThirdImage?.data && (
          <img
            src={BASE_PATH + data.ThirdImage.data.attributes.url}
            alt={data.ThirdImage.data.attributes.alternativeText}
            width={data.ThirdImage.data.attributes.width}
            height={data.ThirdImage.data.attributes.height}
            style={{
              maxWidth: '100%',
              height: 'auto',
            }}
          />
        )}
    </div>
  );

  return (
    <section
      className={classnames(
        styles.container,
        data.StackOnMobile && isMobile ? styles.stacked : ''
      )}
    >
      {firstColumn}
      {secondColumn}
      {thirdColumn}
    </section>
  );
}
