import { useLocation } from "react-router-dom"
import styles from '../styles/components/HeaderMenu.module.scss';
import { useState } from 'react';
import classnames from 'classnames';

export default function HeaderMenu({
  isHamburgerOpened,
  isMobile,
  componentData,
}) {
  const location = useLocation()

  const [dropdown, setDropdown] = useState(false);

  return (
    <>
      <div
        className={classnames(
          isMobile ? styles.mobileWrapper : '',
          isMobile
            ? isHamburgerOpened
              ? styles.hamburgerOpened
              : styles.hamburgerClosed
            : ''
        )}
      >
        <ul
          className={styles.headerMenuContainer}
          onMouseLeave={() => {
            if (!isMobile) {
              setDropdown(false);
            }
          }}
        >
          {componentData &&
            componentData.Menu.map((item, index) => {
              if (!item.Has_submenu) {
                return (
                  <li
                    key={index}
                    className={styles.active}
                    onMouseEnter={() => {
                      if (!isMobile) {
                        setDropdown(false);
                      }
                    }}
                  >
                    <span>
                      <a href={item.Link_URL}>
                        {item.Link_text}
                      </a>
                    </span>
                  </li>
                );
              } else {
                return (
                  <li
                    key={index}
                    className={styles.active}
                    onMouseEnter={() => {
                      if (!isMobile) {
                        setDropdown(true);
                      }
                    }}
                    onClick={() => {
                      if (isMobile) {
                        setDropdown((previousState) => !previousState);
                      }
                    }}
                  >
                    <span>{item.Link_text}</span>
                    <ul
                      className={[
                        styles.expandedMenu,
                        dropdown ? styles.active : '',
                      ].join(' ')}
                    >
                      {item.Sub_menu &&
                        item.Sub_menu.map((item, index) => {
                          return (
                            <li
                              key={index}
                              className={
                                location.pathname === item.Link_URL
                                  ? styles.active
                                  : ''
                              }
                            >
                              <a href={item.Link_URL}>
                                {item.Link_text}
                              </a>
                            </li>
                          );
                        })}
                    </ul>
                  </li>
                );
              }
            })}
        </ul>
      </div>
    </>
  );
}
