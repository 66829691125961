import styles from '../styles/components/GetInTouch.module.scss';
import ReactMarkdown from 'react-markdown';

export default function GetInTouch({ data }) {
  const BASE_PATH = process.env.REACT_APP_BACKEND_URL;

  const socialLinks = data?.SocialLinks?.map((element, index) => {
    return (
      <li className={styles.socialLinksItem} key={index}>
        <a href={element.Link_URL} target="_blank" rel="noopener noreferrer">
          <img
            src={BASE_PATH + element.Image.data.attributes.url}
            width={element.Image.data.attributes.width}
            height={element.Image.data.attributes.height}
            alt={element.Image_alt_text}
            style={{
              maxWidth: '100%',
              height: 'auto',
            }}
          />
        </a>
      </li>
    );
  });

  const contact = data?.Contact?.map((element, index) => {
    const mailTo =
      'mailto:' +
      element.Target_Email +
      '?subject=' +
      encodeURIComponent(element.Target_Subject);

    return (
      <li key={index}>
        <div className={styles.textWrapper}>
          <ReactMarkdown>{element.Text}</ReactMarkdown>
        </div>
        <div className={styles.buttonWrapper}>
          <a href={mailTo}>{element.Button_Label}</a>
        </div>
      </li>
    );
  });

  return (
    <section className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.title}>{data?.Title}</div>
        <ul className={styles.socialLinksContainer}>{socialLinks}</ul>
        <ul className={styles.contactContainer}>{contact}</ul>
      </div>
    </section>
  );
}
