import styles from '../styles/components/TitleBlock.module.scss';

export default function TitleBlock({ data }) {
  return (
    <section className={styles.container}>
      <h1>{data?.Title}</h1>
      <div>{data?.Subtitle}</div>
    </section>
  );
}
