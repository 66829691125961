import styles from '../styles/components/Header.module.scss';
import { useContext, useState, useEffect } from 'react';
import HeaderLogo from './HeaderLogo';
import HeaderMenu from './HeaderMenu';
import { MobileContext } from '../context/mobileContext';
import axios from 'axios';

export default function Header() {
  const [hamburgerOpened, setHamburgerOpened] = useState(false);
  const isMobile = useContext(MobileContext);
  const [componentData, setComponentData] = useState(null);
  const BASE_PATH = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    axios.get(BASE_PATH + '/api/header?populate=deep').then((response) => {
      setComponentData(response.data?.data?.attributes);
    });
  }, [BASE_PATH]);

  const clickHandler = () => {
    setHamburgerOpened((previousState) => !previousState);
  };

  const hamburgerMenu = (
    <div onClick={clickHandler} className={styles.hamburger}>
      <div className={styles.hamburgerPatty}></div>
      <div className={styles.hamburgerPatty}></div>
      <div className={styles.hamburgerPatty}></div>
    </div>
  );

  return (
    <nav className={styles.header}>
      <div className={styles.menu}>
        <HeaderLogo componentData={componentData} />
        {isMobile && hamburgerMenu}
      </div>
      <HeaderMenu
        isHamburgerOpened={hamburgerOpened}
        isMobile={isMobile}
        componentData={componentData}
      />
    </nav>
  );
}
