import React from 'react';
import Vimeo from '@u-wave/react-vimeo';
import styles from '../styles/components/HeroVideo.module.scss';

export default function HeroVideo({ data }) {
  const BASE_PATH = process.env.REACT_APP_BACKEND_URL;

  const placeholder = data?.Video_placeholder?.data ? (
    <img
      src={BASE_PATH + data.Video_placeholder.data.attributes.url}
      alt={data.Video_placeholder.data.attributes.alternativeText}
      width={data.Video_placeholder.data.attributes.width}
      height={data.Video_placeholder.data.attributes.height}
      style={{
        maxWidth: '100%',
        height: 'auto',
      }}
    />
  ) : null;

  return (
    <div className={styles.heroVideo}>
      {!data.Embed && data.Hero_video.data && (
        <video
          autoPlay
          muted
          loop
          src={BASE_PATH + data.Hero_video.data.attributes.url}
        ></video>
      )}
      {!data.Embed && !data.Hero_video.data && placeholder}
      {data.Embed && data.Hero_embed && (
        <Vimeo
          video={data.Hero_embed}
          autoplay
          background={true}
          loop={true}
          muted={true}
          responsive={true}
        />
      )}
      {data.Embed && !data.Hero_embed && placeholder}
    </div>
  );
}
