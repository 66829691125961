import styles from '../styles/components/TextContent.module.scss';
import ReactMarkdown from 'react-markdown';

export default function TextContent({ data }) {
  return (
    <section className={styles.container}>
      <div className={styles.mainText}>
        <ReactMarkdown>{data?.MainTextArea}</ReactMarkdown>
      </div>
      <div className={styles.secondaryText}>
        <ReactMarkdown>{data?.SecondaryTextArea}</ReactMarkdown>
      </div>
    </section>
  );
}
