import styles from '../styles/components/VideoBackgroundAndText.module.scss';
import ReactMarkdown from 'react-markdown';
import classnames from 'classnames';
import Vimeo from '@u-wave/react-vimeo';

export default function VideoBackgroundAndText({ data }) {
  const BASE_PATH = process.env.REACT_APP_BACKEND_URL;

  const ImageFallback = data?.ImageFallback?.data ? (
    <img
      src={BASE_PATH + data?.ImageFallback.data.attributes.url}
      alt={data?.ImageFallback.data.attributes.alternativeText}
      width={data?.ImageFallback.data.attributes.width}
      height={data?.ImageFallback.data.attributes.height}
      style={{
        maxWidth: '100%',
        height: 'auto',
      }}
    />
  ) : null;

  const LeftTitle = (
    <ReactMarkdown className={classnames(styles.title, styles.leftTitle)}>
      {data?.LeftTitle}
    </ReactMarkdown>
  );
  const RightTitle = (
    <ReactMarkdown className={classnames(styles.title, styles.rightTitle)}>
      {data?.RightTitle}
    </ReactMarkdown>
  );
  return (
    <section className={styles.container}>
      {!data.Embed && data.Video.data && (
        <div
          className={styles.videoWrapper}
          dangerouslySetInnerHTML={{
            __html: `
            <video
              muted
              autoplay
              loop
              playsinline
              src="${BASE_PATH + data.Video.data.attributes.url}"   
            />`,
          }}
        ></div>
      )}
      {!data.Embed && !data.Video.data && ImageFallback}
      {data.Embed && data.VideoEmbed && (
        <Vimeo
          video={data.VideoEmbed}
          autoplay
          background={true}
          loop={true}
          muted={true}
          responsive={true}
        />
      )}
      {data.Embed && !data.VideoEmbed && ImageFallback}
      {LeftTitle}
      {RightTitle}
    </section>
  );
}
