import styles from '../styles/components/VisualContentTwoColumns.module.scss';
import Vimeo from '@u-wave/react-vimeo';
import { MobileContext } from '../context/mobileContext';
import classnames from 'classnames';
import { useContext, useState, useEffect } from 'react';

export default function VisualContentTwoColumns({ data }) {
  const BASE_PATH = process.env.REACT_APP_BACKEND_URL;
  const isMobile = useContext(MobileContext);
  const [columnLayout, setColumnLayout] = useState(3); //Default style 50% : 50%

  useEffect(() => {
    if (data.ColumnLayout?.split(' ')[1] === '25') {
      setColumnLayout(1);
    } else if (data.ColumnLayout?.split(' ')[1] === '33') {
      setColumnLayout(2);
    } else if (data.ColumnLayout?.split(' ')[1] === '50') {
      setColumnLayout(3);
    } else if (data.ColumnLayout?.split(' ')[1] === '67') {
      setColumnLayout(4);
    } else if (data.ColumnLayout?.split(' ')[1] === '75') {
      setColumnLayout(5);
    }
  }, [data.ColumnLayout]);

  const firstColumn = (
    <div>
      {data?.FirstEmbed && (
        <Vimeo
          video={data.FirstEmbed}
          autoplay={data.FirstVideoAutoplay}
          background={data.FirstVideoAutoplay}
          loop={true}
          muted={true}
          responsive={true}
        />
      )}
      {!data?.FirstEmbed && data?.FirstVideo?.data && (
        <video
          autoPlay={data?.FirstVideoAutoplay}
          muted
          loop
          src={BASE_PATH + data.FirstVideo.data.attributes.url}
        />
      )}
      {!data?.FirstEmbed &&
        !data?.FirstVideo?.data &&
        data?.FirstImage?.data && (
          <img
            src={BASE_PATH + data.FirstImage.data.attributes.url}
            alt={data.FirstImage.data.attributes.alternativeText}
            width={data.FirstImage.data.attributes.width}
            height={data.FirstImage.data.attributes.height}
            style={{
              maxWidth: '100%',
              height: 'auto',
            }}
          />
        )}
    </div>
  );
  const secondColumn = (
    <div>
      {data?.SecondEmbed && (
        <Vimeo
          video={data.SecondEmbed}
          autoplay={data.SecondVideoAutoplay}
          background={data.SecondVideoAutoplay}
          loop={true}
          muted={true}
          responsive={true}
        />
      )}
      {!data?.SecondEmbed && data?.SecondVideo?.data && (
        <video
          autoPlay={data?.SecondVideoAutoplay}
          muted
          loop
          src={BASE_PATH + data.SecondVideo.data.attributes.url}
        />
      )}
      {!data?.SecondEmbed &&
        !data?.SecondVideo?.data &&
        data?.SecondImage?.data && (
          <img
            src={BASE_PATH + data.SecondImage.data.attributes.url}
            alt={data.SecondImage.data.attributes.alternativeText}
            width={data.SecondImage.data.attributes.width}
            height={data.SecondImage.data.attributes.height}
            style={{
              maxWidth: '100%',
              height: 'auto',
            }}
          />
        )}
    </div>
  );

  return (
    <section
      className={classnames(
        styles.container,
        data.StackOnMobile && isMobile ? styles.stacked : '',
        columnLayout === 1 && styles.columnLayout1,
        columnLayout === 2 && styles.columnLayout2,
        columnLayout === 3 && styles.columnLayout3,
        columnLayout === 4 && styles.columnLayout4,
        columnLayout === 5 && styles.columnLayout5
      )}
    >
      {firstColumn}
      {secondColumn}
    </section>
  );
}
