import Header from '../components/Header';
import Footer from '../components/Footer';
import TitleBlock from '../components/TitleBlock';
import AnimatedImage from '../components/AnimatedImage';
import TwoColumnText from '../components/TwoColumnText';
import VideoBackgroundAndText from '../components/VideoBackgroundAndText';
import LogoPanel from '../components/LogoPanel';
import Quote from '../components/Quote';
import ProfileCarousel from '../components/ProfileCarousel';
import styles from '../styles/pages/About.module.scss';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

export default function About() {
  const [pageData, setPageData] = useState(null);
  const BASE_PATH = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    axios.get(BASE_PATH + '/api/about-page?populate=deep').then((response) => {
      setPageData(response.data?.data?.attributes);
    });
  }, [BASE_PATH]);

  return (
    <div className={styles.container}>
      <Helmet>
        <title>{pageData?.Page_title}</title>
        <meta name="title" content={pageData?.Meta_title} />
        <meta name="description" content={pageData?.Meta_description} />
        <link rel="icon" href="/favicon.png" />
      </Helmet>

      <Header />

      <main className={styles.main}>
        {pageData?.Components.map((item, index) => {
          switch (item.__component) {
            case 'generic-components.title-block':
              return <TitleBlock data={item} key={index} />;
            case 'generic-components.animated-image':
              return <AnimatedImage data={item} key={index} />;
            case 'generic-components.two-column-text':
              return <TwoColumnText data={item} key={index} />;
            case 'generic-components.logo-panel':
              return <LogoPanel data={item} key={index} />;
            case 'generic-components.video-background-and-text':
              return <VideoBackgroundAndText data={item} key={index} />;
            case 'generic-components.quote':
              return <Quote data={item} key={index} />;
            case 'generic-components.profile-carousel':
              return <ProfileCarousel data={item} key={index} />;
            default:
              return null;
          }
        })}
      </main>

      <Footer />
    </div>
  );
}
