import styles from '../styles/components/LogoPanel.module.scss';
import { useContext } from 'react';
import { MobileContext } from '../context/mobileContext';

export default function LogoPanel({ data }) {
  const BASE_PATH = process.env.REACT_APP_BACKEND_URL;
  const isMobile = useContext(MobileContext);

  const Logos = data?.Logos?.data.map((element) => {
    return (
      <div key={element.id} className={styles.logoWrapper}>
        <img
          src={BASE_PATH + element.attributes.url}
          alt={element.attributes.alternativeText}
          width={element.attributes.width}
          height={element.attributes.height}
          style={{
            maxWidth: '100%',
            height: 'auto',
          }}
        />
      </div>
    );
  });

  const Title = <div className={styles.title}>{data.Title}</div>;

  return (
    <section className={styles.container}>
      {!isMobile && Title}
      <div className={styles.logoGrid}>{Logos}</div>
    </section>
  );
}
