import styles from '../styles/components/ProfileCarousel.module.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import classnames from 'classnames';
import { useState, useContext } from 'react';
import ReactMarkdown from 'react-markdown';
import { MobileContext } from '../context/mobileContext';

export default function ProfileCarousel({ data }) {
  const BASE_PATH = process.env.REACT_APP_BACKEND_URL;
  const [isActive, setIsActive] = useState(0); //Index of the visible profile
  const isMobile = useContext(MobileContext);

  const Profiles = data.Profiles.map((element, index) => {
    return (
      <div
        key={element.id}
        onClick={() => {
          setIsActive(index);
        }}
        className={classnames(
          classnames(
            styles.profileWrapper,
            isActive === index && styles.activeProfile
          )
        )}
      >
        <img
          src={BASE_PATH + element.Image?.data.attributes.url}
          alt={element.Image?.data.attributes.alternativeText}
          width={element.Image?.data.attributes.width}
          height={element.Image?.data.attributes.height}
          style={{
            maxWidth: '100%',
            height: 'auto',
          }}
        />
      </div>
    );
  });

  const ProfileDescription = data.Profiles.map((element, index) => {
    return (
      isActive === index && (
        <div
          className={classnames(styles.profileDescriptionWrapper)}
          key={index}
        >
          <div className={styles.profileName}>{data.Profiles[index].Name}</div>
          <div className={styles.profileTitle}>
            {data.Profiles[index].Title}
          </div>
          <ReactMarkdown className={styles.profileBio}>
            {data.Profiles[index].Bio}
          </ReactMarkdown>
        </div>
      )
    );
  });

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: isMobile ? 3 : 5,
    slidesToScroll: 1,
    arrows: true,
    swipe: !isMobile ? false : true,
    swipeToSlide: !isMobile ? false : true,
    touchThreshold: 100,
    focusOnSelect: true,
    centerMode: true,
    centerPadding: 0,
    beforeChange: (current, next) => {
      setIsActive(next);
    },
  };
  return (
    <section className={styles.container}>
      <Slider {...settings}>{Profiles}</Slider>
      <div className={styles.profileDescriptionContainer}>
        {ProfileDescription}
      </div>
    </section>
  );
}
