import styles from '../styles/components/PolicyTable.module.scss';

export default function PolicyTable({ data }) {
  const twoColumnLayout = (
    <div className={styles.twoColumnContainer}>
      <div className={styles.column}>
        <div className={styles.columnHead}>
          <div> {data.table_rows.data[0].attributes.Head}</div>
        </div>
        {data.table_rows.data[0].attributes.Copy.map((item, index) => {
          return (
            <div className={styles.columnItem} key={index}>
              {item.Text}
            </div>
          );
        })}
      </div>
      <div className={styles.column}>
        <div className={styles.columnHead}>
          <div> {data.table_rows.data[1].attributes.Head}</div>
        </div>
        {data.table_rows.data[1].attributes.Copy.map((item, index) => {
          return (
            <div className={styles.columnItem} key={index}>
              {item.Text}
            </div>
          );
        })}
      </div>
    </div>
  );

  const rowsLayout = (
    <div className={styles.rowsContainer}>
      {data.table_rows.data.map((item, index) => {
        return (
          <div className={styles.row} key={index}>
            <div className={styles.rowHead}>{item.attributes.Head}</div>
            <div className={styles.rowText}>{item.attributes.Copy[0].Text}</div>
          </div>
        );
      })}
    </div>
  );

  return (
    <div className={styles.policyTable}>
      {data.ColumnLayout && twoColumnLayout}
      {!data.ColumnLayout && rowsLayout}
    </div>
  );
}
